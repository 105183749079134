import React from 'react';
import './ContactPage.css';

class ContactPage extends React.Component {

render() {
    return (
<>
This is the Contact Page Component
</>
    )
}
}
export default ContactPage; 